html, body {
  position: relative;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  display: block;
  width: 100%;
  /* background-image: repeating-linear-gradient(to bottom right, #E3EEFF, #E6EDFC, #EBEBF5, #EFE9EF, #F3E7E9); */
  /* background-image: linear-gradient(135deg, rgba(227,238,255,1) 0%,rgba(239,233,239,1) 38%,rgba(239,233,239,1) 57%,rgba(227,238,255,1) 100%); */
  /* background-image: repeating-linear-gradient(to bottom, rgba(227,238,255,1) 0%,rgba(239,233,239,1) 38%,rgba(239,233,239,1) 57%,rgba(227,238,255,1) 100%); */
  background: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: inherit;
  text-decoration: none;
}
