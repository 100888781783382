/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on April 11, 2020 */



@font-face {
    font-family: 'bebas_neueregular';
    src: url('bebasneue-webfont.woff2') format('woff2'),
         url('bebasneue-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'd-dinregular';
    src: url('din-webfont.woff2') format('woff2'),
         url('din-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}